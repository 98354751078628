@import "~antd/dist/antd.css";

.headerBox {
  max-width: 7.5rem;
  margin: 0 auto;
  position: relative;

  .header {
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
  }
}

.nav {
  height: 30px;
}

.logo {
  height: 1.88rem;
  position: relative;
  overflow: hidden;
  background-image: url("/asset/newTop.png");
  background-size: cover;
  z-index: -1;

  & > img {
    width: 3.43rem;
    height: 0.76rem;
    margin-left: 0.35rem;
    margin-top: 0.56rem;
  }

  p.des {
    width: 94.5px;
    font-size: 12px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #999999;
  }

  & > div {
    position: absolute;
    width: 281.5px;
    top: 0px;
    right: -281.5px;
    z-index: -1;
    -webkit-animation: slide-in 1.5s forwards;
    animation: slide-in 1.5s forwards;

    & > img {
      width: 281.5px;
      height: 338.5px;
    }
  }
}

.content {
  padding: 0.7rem 0.38rem 0 0.28rem;

  .title {
    font-size: 0.32rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #111111;
  }

  .titleTwo {
    margin-top: 0.55rem;
    margin-bottom: 0.16rem;
  }

  .article {
    font-size: 0.22rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #bfaf70;
    line-height: 0.36rem;
    margin-top: 0.57rem;

    div {
      margin-bottom: 0.36rem;
    }
  }
}

.newDown {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-top: 0.38rem;
  padding: 0 0.2rem;

  .downLeft {
    .rightDown {
      display: block;
      background: #000000;
      color: #fff;
    }

    .leftDown {
      background: #fdc006;
      color: #111111;
      margin-bottom: 0.2rem;
    }

    .divBox {
      width: 2.76rem;
      height: 0.86rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      img {
        width: 0.35rem;
        height: 0.42rem;
      }
    }
  }

  .downright {
    width: 1.7rem;
    height: 1.7rem;
    background: #000000;
    border-radius: 0.12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.56rem;

    img {
      width: 1.39rem;
      visibility: middle;
    }
  }
}

.bottomImg {
  background-image: url("/asset/bottomImg.png");
  background-size: 100% 100%;
  min-height: 1.72rem;
  margin-top: 0.9rem;
}

@-webkit-keyframes slide-in {
  100% {
    right: 0;
  }
}

@keyframes slide-in {
  100% {
    right: 0;
  }
}

.version {
  height: 77.5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #595959;

  p {
    margin-bottom: 7px;
  }

  & > span {
    display: inline-block;

    &:nth-child(1) {
      width: 200px;
    }

    i {
      margin-right: 10px;
    }
  }
}
